:root {
  --primary: #003366;
  --primary-80: #426588;
  --secondary: #00c2df;
  --negative: #cc0000;
  --positive: #00a79d;
  --light-primary: #ebeff3;
  --black: black;
  --yellow-700: #db9d00;
  --yellow-500: #655b37;
  --green: #00A79D;
  --gray-5: #E0E0E0;
  --gray-6: #F2F2F2;
}

summary, header, div {
  color: black !important;
}

.left-landing-page {
  background: white !important;
}

@media (max-width: 850px) {
  .landing-page-toolbar {
    background-color: var(--primary) !important;
    color: white !important;
  }
}

.toolbar-link {
  color: var(--primary) !important;
}
@media (max-width: 850px) {
  .toolbar-link {
    color: white !important;
  }
}

.check-bulletpoint {
  color: var(--primary) !important;
}

.email-link, .website-link {
  color: var(--primary) !important;
  text-decoration: underline !important;
}

.email-link:disabled, .website-link:disabled {
  color: var(--primary-80) !important;
  text-decoration: underline !important;
}

.landing-page-right-div {
  background-color: var(--primary) !important;
}

#login-form {
  box-shadow: inset 0px -4px 0px var(--secondary);
}

@media (max-width: 850px) {
  #login-form, .create-account-title, .signUpSummary {
    background-color: var(--primary) !important;
    color: white !important;
  }
}

@media (min-width: 850px) {
  .free, .sign-up-mobile {
    color: var(--primary) !important;
  }
}

.sign-up-btn {
  color: white !important;
  background-color: var(--primary) !important;
}
@media (max-width: 850px) {
  .sign-up-btn {
    background-color: var(--primary-80) !important;
  }
}

.sign-btn-label {
  color: white !important;
}

.login-email-input {
  color: var(--primary) !important;
  background-color: var(--light-primary) !important;
  border: 1px solid var(--primary) !important;
}

@media (max-width: 850px) {
  .featureListDiv, .contactInfoDiv {
    background-color: var(--gray-6) !important;
  }
}

.displayRoot {
  background-color: var(--primary) !important;
}
@media (max-width: 850px) {
  .displayRoot {
    background-color: var(--gray-6) !important;
  }
}

.mainDisplay {
  background-color: white !important;
}
@media (max-width: 850px) {
  .mainDisplay {
    background: var(--gray-6) !important;
  }
}

.displayContainer {
  background-color: var(--gray-6) !important;
  border: 1px solid var(--gray-5) !important;
}

.displayHeader {
  color: black !important;
  min-height: 30px !important;
}

@media (max-width: 850px) {
  .outerBottomDiv {
    background-color: var(--gray-6) !important;
    flex-direction: column-reverse;
    padding: 0 8px;
    margin: 0;
  }
}

@media (max-width: 850px) {
  .stepHolder {
    background: var(--gray-6) !important;
    border-bottom: 1px solid var(--gray-5) !important;
  }
}

.step-number.step-current {
  color: white !important;
  background-color: var(--primary) !important;
}

.step-number.step-complete .check-icon {
  color: white !important;
  background-color: var(--primary) !important;
}

.step-number.step-complete {
  color: white !important;
  background-color: var(--primary) !important;
}

.next-button, .done-btn, .next-button div {
  color: white !important;
  background-color: var(--primary) !important;
}

.next-button:disabled, .done-btn:disabled, .next-button:disabled div {
  color: white !important;
  background-color: var(--light-primary) !important;
}

.topDisplaySection {
  background-color: white !important;
}
@media (max-width: 850px) {
  .topDisplaySection {
    margin-top: 80px;
    background-color: var(--gray-6) !important;
  }
}

.congrats-banner {
  background-color: white !important;
}
@media (max-width: 850px) {
  .congrats-banner {
    background-color: var(--gray-6) !important;
  }
}

.done-btn-holder {
  background-color: white !important;
}
@media (max-width: 850px) {
  .done-btn-holder {
    background-color: var(--gray-6) !important;
  }
}

.invite-team-label {
  color: var(--primary) !important;
}

@media (max-width: 850px) {
  .inviteeModalHolder {
    background-color: white !important;
  }
}

.invitee-modal {
  background-color: white !important;
}

.input-field.focused-true {
  border: 2px solid var(--primary) !important;
}

.input-field.focused-false {
  border: 1px solid var(--gray-5) !important;
}

.broker-fields {
  border-bottom: 1px solid var(--gray-5) !important;
}

.tAndC {
  color: var(--primary) !important;
  text-decoration: underline !important;
}

.checkbox-input {
  border: 1px solid var(--gray-5) !important;
}

.checkbox-icon {
  color: var(--primary) !important;
}

.text-input {
  color: black !important;
}

.text-input:focus {
  outline: 2px solid var(--primary) !important;
}

.team-invite-btn {
  background-color: var(--light-primary) !important;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
}

.team-invite-btn:hover {
  background-color: var(--primary) !important;
  color: white !important;
}

.table-row:nth-child(even) {
  background: var(--gray-5) !important;
}
